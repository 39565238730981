import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Card,
  Typography,
  CardContent,
  Select,
  MenuItem,
  TextField,
  Button,
  Chip
} from '@material-ui/core';
import moment from 'moment';
import { sendHttpCall, Toast } from 'shareService/httpService';
import Loader from 'components/Loader';
import { salesStages } from '../../config'; // Import salesStages

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    fontFamily: 'Poppins, sans-serif',
    overflowX: 'auto'
  },
  header: {
    marginBottom: theme.spacing(2),
    color: '#297bca',
    textAlign: 'center'
  },
  filterContainer: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  filterSelect: {
    marginRight: theme.spacing(2),
    minWidth: 200
  },
  filterButton: {
    minWidth: 100,
    height: '100%'
  },
  card: {
    marginBottom: theme.spacing(2),
    border: '1px solid #ccc',
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    overflow: 'hidden',
    backgroundColor: '#f9f9f9',
    width: 250,
    height: 80
  },
  unassignedCard: {
    marginBottom: theme.spacing(2),
    border: '2px solid #6a1b9a', // Dark purple for border
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    overflow: 'hidden',
    backgroundColor: '#f3e5f5', // Light purple for background
    width: 250,
    height: 80
  },
  unassignedChip: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    backgroundColor: '#6a1b9a', // Dark purple for chip
    color: '#fff',
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(1)
  },
  revenueCard: {
    marginBottom: theme.spacing(2),
    border: '2px solid #155724',
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    overflow: 'hidden',
    backgroundColor: '#d4edda',
    width: 250,
    height: 80
  },
  missedCard: {
    marginBottom: theme.spacing(2),
    border: '2px solid #d32f2f',
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    overflow: 'hidden',
    backgroundColor: '#f8d7da',
    width: 250,
    height: 80
  },
  missedChip: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    backgroundColor: '#d32f2f',
    color: '#fff',
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(1)
  },
  cardContent: {
    padding: theme.spacing(1)
  },
  totalBox: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    backgroundColor: '#297bca',
    color: '#fff',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    textAlign: 'center',
    marginBottom: theme.spacing(2)
  },
  totalChip: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    backgroundColor: '#297bca',
    color: '#fff',
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(1)
  },
  revenueChip: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    backgroundColor: '#155724',
    color: '#fff',
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(1)
  }
}));

const WorkReports = (props) => {
  const classes = useStyles();
  const [reportData, setReportData] = useState({
    opportunitiesRevenue: 0,
    unassignedOpportunities: 0,
    missedOppFollowUps: 0,
    opportunitiesData: []
  });
  const [salesAdmins, setSalesAdmin] = useState([]);
  const [selectedAdminId, setSelectedAdminId] = useState('');
  const [selectedAdminName, setSelectedAdminName] = useState('');
  const [loading, setLoading] = useState(false);
  const today = moment(new Date()).format('YYYY-MM-DD');
  const [selectedStartDate, setSelectedStartDate] = useState(today);
  const [selectedEndDate, setSelectedEndDate] = useState(today);
  const [selectedPeriod, setSelectedPeriod] = useState('Today');

  const fetchData = async () => {
    setLoading(true);
    const query = `?start_date=${selectedStartDate}&end_date=${selectedEndDate}&adminId=${selectedAdminId}`;
    try {
      const response = await sendHttpCall('GET', `sales/work_report${query}`);
      setReportData({
        opportunitiesRevenue: response.opportunitiesRevenue || 0,
        unassignedOpportunities: response.unassignedOpportunities || 0,
        missedOppFollowUps: response.missedOppFollowUps || 0,
        opportunitiesData: response.opportunitiesData || []
      });
    } catch (error) {
      Toast('Error fetching data');
    }
    setLoading(false);
  };

  const getSalesAdmins = () => {
    let url = `admin/users/SALES/ACTIVE`;
    sendHttpCall('GET', url, null)
      .then((response) => {
        const formattedAdmins = response.data.map((admin) => ({
          label: `${admin.name} (${admin.email_id})`,
          value: admin.admin_id
        }));
        setSalesAdmin(formattedAdmins);
      })
      .catch((error) => {
        console.log('Error fetching sales admins:', error);
      });
  };

  useEffect(() => {
    const storedAdminId = sessionStorage.getItem('reportAdminId');
    const storedTimePeriod = sessionStorage.getItem('reportTimePeriod');
  
    if (storedAdminId) {
      setSelectedAdminId(storedAdminId);
    }
  
    if (storedTimePeriod) {
      setSelectedPeriod(storedTimePeriod);
      handleTimeChange({ target: { value: storedTimePeriod } }, false);
    }
  
    getSalesAdmins(); 
  
  }, []);

  useEffect(() => {
    if (selectedAdminId || selectedPeriod) {
      fetchData(); 
    }
  }, [selectedAdminId, selectedPeriod]);
  

  const handleAdminChange = (event) => {
    const adminId = event.target.value;
    setSelectedAdminId(adminId);
    const selectedAdmin = salesAdmins.find((admin) => admin.value === adminId);
    setSelectedAdminName(selectedAdmin ? selectedAdmin.label : '');

    sessionStorage.setItem('reportAdminId', adminId);
  };

  const handleTimeChange = (event, shouldStoreInSession = true) => {
    const period = event.target.value;
    setSelectedPeriod(period);
  
    let endDate = new Date();
    let startDate = new Date();
  
    switch (period) {
      case 'Last 30 days':
        startDate.setDate(endDate.getDate() - 30);
        break;
      case 'Last 7 days':
        startDate.setDate(endDate.getDate() - 7);
        break;
      case 'Yesterday':
        startDate.setDate(endDate.getDate() - 1);
        endDate.setDate(endDate.getDate() - 1);
        break;
      case 'This Month':
        startDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1);
        break;
      case 'Previous Month':
        startDate = new Date(endDate.getFullYear(), endDate.getMonth() - 1, 1);
        endDate = new Date(endDate.getFullYear(), endDate.getMonth(), 0);
        break;
        case 'Last 6 Months':
          // start month and date will be 6 months before todays date
          startDate = new Date(endDate.getFullYear(), endDate.getMonth() - 6, 1);
          break;
      case 'Last Year':
        startDate = new Date(endDate.getFullYear() - 1, 0, 1);
        endDate = new Date(endDate.getFullYear() - 1, 11, 31);
        break;
      default:
        break;
    }
  
    // Automatically update start and end date based on the period
    if (period !== 'Custom Date') {
      const formattedStart = moment(startDate).format('YYYY-MM-DD');
      const formattedEnd = moment(endDate).format('YYYY-MM-DD');
      setSelectedStartDate(formattedStart);
      setSelectedEndDate(formattedEnd);
    }
  
    // Only store in sessionStorage if this is triggered by a user action
    if (shouldStoreInSession) {
      sessionStorage.setItem('reportTimePeriod', period);
    }
  };
  

  const handleBoxClick = (type, salesDataType = '', stageId = '') => {
    if (type === 'opportunities') {
      const stageData = reportData.opportunitiesData.find(
        (data) => data.sales_stage_id === stageId
      );
      if (stageId && (!stageData || stageData.user_count === 0)) {
        const stageLabel = salesStages.find(stage => stage.value === stageId)?.label || 'This stage';
        Toast(`${stageLabel} has no data to view.`);
        return; 
      }
    } 

    else if (salesDataType === 'revenue' && (!reportData.opportunitiesRevenue || reportData.opportunitiesRevenue === 0)) {
      Toast('No revenue to view.');
      return;
    } 

    else if (salesDataType === 'missed_followUps' && reportData.missedOppFollowUps === 0) {
      Toast('No missed follow-ups to view.');
      return; 
    } 

    else if (salesDataType === 'unassigned' && reportData.unassignedOpportunities === 0) {
      Toast('No unassigned opportunities to view.');
      return;
    }
  

    const url = `/data/${type}/Today/${selectedStartDate}/${selectedEndDate}?salesDataType=${salesDataType}${
      stageId ? `&stageId=${stageId}` : ''
    }${selectedAdminId ? `&adminId=${selectedAdminId}` : ''}`;
    props.history.push(url); 
  };
  
  

  const renderOpportunitiesSection = () => {
    const totalOpportunities = reportData.opportunitiesData.reduce(
      (acc, curr) => acc + curr.user_count,
      0
    );

    return (
      <div>
        <div className={classes.totalBox}>
          Opportunities: {totalOpportunities}
        </div>
        <Grid container spacing={2}>
          {salesStages.map((stage) => {
            const stageData = reportData.opportunitiesData.find(
              (data) => data.sales_stage_id === stage.value
            );
            if (stageData && stageData.user_count > 0) {
              return (
                <Grid
                  item
                  key={stage.value}
                  onClick={() =>
                    handleBoxClick('opportunities', 'work_report', stage.value)
                  }>
                  <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                      <Typography variant="subtitle1">{stage.label}</Typography>
                      <Chip
                        label={stageData.user_count}
                        color="primary"
                        variant="outlined"
                        className={classes.totalChip}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              );
            }
            return null;
          })}
          {renderRevenueBox()}
          {renderMissedFollowUpsBox()}
          {!selectedAdminId && renderUnassignedOpportunitiesBox()}
        </Grid>
      </div>
    );
  };

  const renderRevenueBox = () => (
    <Grid item onClick={() => handleBoxClick('opportunities', 'revenue')}>
      <Card className={classes.revenueCard}>
        <CardContent className={classes.cardContent}>
          <Typography variant="subtitle1">Total Revenue</Typography>
          <Chip
            label={`₹${reportData.opportunitiesRevenue.toLocaleString()}`}
            className={classes.revenueChip}
          />
        </CardContent>
      </Card>
    </Grid>
  );

  const renderMissedFollowUpsBox = () => (
    <Grid
      item
      onClick={() => handleBoxClick('opportunities', 'missed_followUps')}>
      <Card className={classes.missedCard}>
        <CardContent className={classes.cardContent}>
          <Typography variant="subtitle1">Missed Follow-Ups</Typography>
          <Chip
            label={reportData.missedOppFollowUps}
            className={classes.missedChip}
          />
        </CardContent>
      </Card>
    </Grid>
  );

  const renderUnassignedOpportunitiesBox = () => (
    <Grid
      item
      onClick={() =>
        handleBoxClick('opportunities', 'unassigned')
      }>
      <Card className={classes.unassignedCard}>
        <CardContent className={classes.cardContent}>
          <Typography variant="subtitle1">Unassigned Opportunities</Typography>
          <Chip
            label={reportData.unassignedOpportunities}
            className={classes.unassignedChip}
          />
        </CardContent>
      </Card>
    </Grid>
  );

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.header}>
        Work Report
      </Typography>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className={classes.filterContainer}>
            <TextField
              fullWidth
              margin="dense"
              name="status"
              onChange={handleTimeChange}
              required
              select
              SelectProps={{ native: true }}
              value={selectedPeriod}
              variant="outlined"
              className={classes.filterSelect}>
              {[
                'Today',
                'Yesterday',
                'Last 7 days',
                'Last 30 days',
                'This Month',
                'Previous Month',
                'Last 6 Months',
                'Last Year',
                'Custom Date'
              ].map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </TextField>
            <Select
              value={selectedAdminId}
              onChange={handleAdminChange}
              displayEmpty
              className={classes.filterSelect}
              inputProps={{ 'aria-label': 'Without label' }}>
              <MenuItem value="">
                <em>All Admins</em>
              </MenuItem>
              {salesAdmins.map((admin) => (
                <MenuItem key={admin.value} value={admin.value}>
                  {admin.label}
                </MenuItem>
              ))}
            </Select>

            {/* <Button
              variant="contained"
              color="primary"
              onClick={fetchData}
              className={classes.filterButton}>
              Go
            </Button> */}
          </div>
          {renderOpportunitiesSection()}
        </>
      )}
    </div>
  );
};

export default WorkReports;
