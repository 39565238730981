// const prod = false;
const prod = true;

export const apiUrl = prod
  ? 'https://admin-api.ourgoodspace.com/api'
  : 'https://staging-admin-api.ourgoodspace.com/api';
  // : 'http://localhost:4000/api';


export const esocialUrl = prod
  ? 'https://api.ourgoodspace.com/api/d2'
  : 'https://staging-api.ourgoodspace.com/api/d2';
  // : 'http://localhost:5001/api/d2';



export const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: ''
};

export const loginHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};
export const selectoptions = [
  'Today',
  'Yesterday',
  'Last 7 days',
  'Last 30 days',
  //'Last Year',
  'Custom Date'
];

export const selectSalesDatePickerOptions = [
  
  'Tomorrow',
  'Next 7 Days',
  'Next 30 Days',
  'Today',
  'Yesterday',
  'Last 7 days',
  'Last 30 days',
  'This Month',
  'Previous Month',
  'Last 6 Months',
  //'Last Year',
  'Custom Date'
];

export const selectYesterday = [
  'Yesterday',
  'Today',
  'Last 7 days',
  'Last 30 days',
  //'Last Year',
  //'All time',
  'Custom Date'
];

export const salesStages = [
  { value: 1, label: 'Prospect', percentage: 0.00 },
  { value: 2, label: 'Qualified', percentage: 15.00 },
  { value: 3, label: 'Demo', percentage: 40.00 },
  { value: 4, label: 'Quotation', percentage: 75.00 },
  { value: 5, label: 'Signed', percentage: 100.00 },
  { value: 6, label: 'Delivery', percentage: 100.00 },
  { value: 7, label: 'Not Interested', percentage: 0.00 }
];

export const salesIntents = [
  { value: 1, label: 'HOT'},
  { value: 2, label: 'COLD'},
  { value: 3, label: 'WARM'},
];

export const countryOptions = [
  { label: '+91 India', value: '+91', maxLength: 10 },
  { label: '+1 USA', value: '+1', maxLength: 10 }
];

export const currencyOptions = [
  { label: '₹ INR', value: 'INR' },
  { label: '$ USD', value: 'USD' }
];


export const genders = ['BOTH', 'MALE', 'FEMALE'];

export const state = {
  add: 'ADD',
  edit: 'EDIT',
  delete: 'DELETE'
};

export const Card_template = {
  INFO: 'INFO',
  INFO_1: 'INFO_1',
  INFO_5: 'INFO_5',
  BACKGROUND_IMAGE_ONLY: 'BACKGROUND_IMAGE_ONLY'
};

export const ageOptions = [
  {
    id: 0,
    name: '11 - 20',
    from: 11,
    to: 20
  },
  {
    id: 1,
    name: '21 - 30',
    from: 21,
    to: 30
  },
  {
    id: 2,
    name: '31 - 40',
    from: 31,
    to: 40
  },
  {
    id: 3,
    name: '41 - 50',
    from: 41,
    to: 50
  },
  {
    id: 4,
    name: '51 - 60',
    from: 51,
    to: 60
  }
];

export const status = [
  {
    id: 1,
    name: 'ACTIVE'
  },
  {
    id: 2,
    name: 'INACTIVE'
  }
];

export const purpose_type = [
  {
    id: 1,
    name: 'SHOW'
  },
  {
    id: 2,
    name: 'HIDE'
  }
];

export const Auth = {
  token: '',
  id: ''
};

export const message = {
  UPADTE: ' updated successfully',
  ADD: ' created Successfully',
  DELETE: ' Deleted Successfully'
};
